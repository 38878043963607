/**
 * @generated SignedSource<<870cfbca696f696c53cd27ae6f9c0e27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ExportPeopleButton_ExportMutation$variables = {
  name?: string | null;
};
export type ExportPeopleButton_ExportMutation$data = {
  readonly Export: {
    readonly exportPeople: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type ExportPeopleButton_ExportMutation = {
  response: ExportPeopleButton_ExportMutation$data;
  variables: ExportPeopleButton_ExportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExportPeopleButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExportMutations",
        "kind": "LinkedField",
        "name": "Export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExportPeoplePayload",
            "kind": "LinkedField",
            "name": "exportPeople",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExportPeopleButton_ExportMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExportMutations",
        "kind": "LinkedField",
        "name": "Export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExportPeoplePayload",
            "kind": "LinkedField",
            "name": "exportPeople",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2aa9a949da040e12445faab915f646aa",
    "id": null,
    "metadata": {},
    "name": "ExportPeopleButton_ExportMutation",
    "operationKind": "mutation",
    "text": "mutation ExportPeopleButton_ExportMutation(\n  $name: String\n) {\n  Export {\n    exportPeople(input: {name: $name}) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18240c3d5907d1c53cb7e337aa36dda6";

export default node;
