import { graphql } from "babel-plugin-relay/macro";
import { useSelector } from "react-redux";
import { useMutation } from "react-relay";
import { ExportButton } from "@components/export-button";
import { selectPeopleFilters } from "@redux/PeopleSlice";
import { type ExportPeopleButton_ExportMutation } from "@relay/ExportPeopleButton_ExportMutation.graphql";

const MUTATION = graphql`
	mutation ExportPeopleButton_ExportMutation($name: String) {
		Export {
			exportPeople(input: { name: $name }) {
				file {
					url
				}
			}
		}
	}
`;

export const ExportPeopleButton = () => {
	const [doExport, isExporting] = useMutation<ExportPeopleButton_ExportMutation>(MUTATION);
	const filters = useSelector(selectPeopleFilters);

	return (
		<ExportButton
			isExporting={isExporting}
			doExport={(success) => {
				doExport({
					variables: {
						name: filters.filterByName,
					},
					onCompleted: (response) => {
						success(response.Export.exportPeople?.file?.url!);
					},
				});
			}}
		/>
	);
};
