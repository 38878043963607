/**
 * @generated SignedSource<<8f148e2250677b94bcca9aca991d4cf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatePersonInput = {
  clientMutationId?: string | null;
  data: PersonDataInput;
};
export type PersonDataInput = {
  address?: AddressWithGps | null;
  assignmentRoleRef?: string | null;
  associatedWithDivisionsRef?: ReadonlyArray<string> | null;
  associatedWithRegionsRef?: ReadonlyArray<string> | null;
  avatarRef?: string | null;
  comment?: string | null;
  email?: string | null;
  employeeId?: string | null;
  laborBurdenMultiplier?: number | null;
  name: string;
  phone?: string | null;
  salary?: number | null;
  startDate?: any | null;
  superVisorsRef?: ReadonlyArray<string> | null;
};
export type AddressWithGps = {
  city: string;
  country: string;
  latitude?: number | null;
  lineOne: string;
  longitude?: number | null;
  postalCode: string;
  state: string;
};
export type EditPersonModal_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreatePersonInput;
};
export type EditPersonModal_CreateMutation$data = {
  readonly Staff: {
    readonly createPerson: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EditPersonButton_PersonFragment">;
        };
      };
    } | null;
  };
};
export type EditPersonModal_CreateMutation = {
  response: EditPersonModal_CreateMutation$data;
  variables: EditPersonModal_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPersonModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StaffMutations",
        "kind": "LinkedField",
        "name": "Staff",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreatePersonPayload",
            "kind": "LinkedField",
            "name": "createPerson",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PeopleEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditPersonButton_PersonFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPersonModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StaffMutations",
        "kind": "LinkedField",
        "name": "Staff",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreatePersonPayload",
            "kind": "LinkedField",
            "name": "createPerson",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PeopleEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phone",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "salary",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "laborBurdenMultiplier",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentRole",
                        "kind": "LinkedField",
                        "name": "assignmentRole",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Division",
                        "kind": "LinkedField",
                        "name": "associatedWithDivisions",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "associatedWithRegions",
                        "plural": true,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lineOne",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "longitude",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latitude",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comment",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "employeeId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "superVisorsRef",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "966bc9ee9bd14cb63d91c5e0c604da21",
    "id": null,
    "metadata": {},
    "name": "EditPersonModal_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation EditPersonModal_CreateMutation(\n  $input: CreatePersonInput!\n) {\n  Staff {\n    createPerson(input: $input) {\n      edge {\n        node {\n          id\n          ...EditPersonButton_PersonFragment\n        }\n      }\n    }\n  }\n}\n\nfragment EditPersonButton_PersonFragment on Person {\n  ...EditPersonModal_PersonFragment\n}\n\nfragment EditPersonModal_PersonFragment on Person {\n  id\n  name\n  email\n  phone\n  salary\n  laborBurdenMultiplier\n  startDate\n  assignmentRole {\n    id\n    name\n  }\n  associatedWithDivisions {\n    id\n    name\n  }\n  associatedWithRegions {\n    id\n    name\n  }\n  address {\n    lineOne\n    postalCode\n    city\n    state\n    country\n    longitude\n    latitude\n  }\n  comment\n  avatar {\n    id\n    url\n  }\n  employeeId\n  superVisorsRef {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9f07dd2c6ba5e7a2c09ec136b74b357";

export default node;
